import {IWixAPI, StructurePage} from '@wix/native-components-infra/dist/src/types/types'
import * as navigation from '../../../commons/services/navigation'
import {MembersSettings, MembersTexts} from '../../../commons/enums'
import {MemberPageState} from '../types/state'

export const getUserText = (state: MemberPageState, key: MembersTexts) => state.component.settings.texts[key]
export const getSetting = (state: MemberPageState, key: MembersSettings) => state.component.settings[key]

export const getNoEventsPage = async (state: MemberPageState, wixCodeApi: IWixAPI): Promise<StructurePage> => {
  const pageId = getSetting(state, MembersSettings.noUpcomingEventsLinkPageId)
  let page = pageId && (await navigation.getPage(wixCodeApi, pageId))
  page = page ? page : await navigation.getHomePage(wixCodeApi)
  return page
}
