import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {EventLink} from '../../styled-components/event-link'
import {ShareButtonProps} from '.'

export const ShareButton = ({mobile, shareEvent, event}: ShareButtonProps) => {
  const {t} = useTranslation()
  return (
    <EventLink mobile={mobile} onClick={() => shareEvent(event)} data-hook={DH.BUTTON_SHARE}>
      {t('events.buttons.share-event')}
    </EventLink>
  )
}
